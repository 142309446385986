import React, { Fragment } from 'react';

export default class GraphBody extends React.Component {
  // resolution in x and y directions
  static defaultProps = { resolution: [100, 100] };

  preparePolygon() {
    // [right top, right btm, left bottom, otherpoints...]
    let points = [`${this.props.x+this.props.width},${this.props.y-this.props.height} ${this.props.x+this.props.width},${this.props.y} ${this.props.x},${this.props.y}`];

    let collector = this.props.data.map(chunk => {
      let xNext = this.props.x + chunk[0] * this.props.width/this.props.resolution[0];
      let yNext = this.props.y - chunk[1] * this.props.height/this.props.resolution[1];
      return ` ${xNext},${yNext}`;
    });

    return points.concat(collector).join(' ')
  }

  prepareDots() {
    let key=1
    return this.props.data.map(chunk => {
      let xNext = this.props.x + chunk[0] * this.props.width/this.props.resolution[0]
      let yNext = this.props.y - chunk[1] * this.props.height/this.props.resolution[1]
      return <circle key={`${xNext}${yNext}`} cx={xNext} cy={yNext} r={2.5} className="fill-current text-blue-500"/>
    })
  }

  render() {
    let points = this.preparePolygon()
    let circles = this.prepareDots()
    return(
      <Fragment>
        <polygon points={points} className="stroke-current text-red-500" />
        {circles}
      </Fragment>
    )
  }
}
