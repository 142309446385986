import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import OrderScaleShape from "../components/OrderScaleShape"
import SubscribeForm from "../components/SubscribeForm"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Logo } from '../../static/img/logo.svg'

const LandingPage = () => {
  let placeholderImg = (
    <svg className="w-full" xmlns="http://www.w3.org/2000/svg" width="555" height="380" viewBox="0 0 555 380">
      <rect fill="#ddd" width="555" height="380"/>
      <text fill="rgba(0,0,0,0.5)" font-family="sans-serif" font-size="30" dy="10.5" font-weight="bold" x="50%" y="50%" text-anchor="middle">555x380</text>
    </svg>
  )

  const ButtonsLongShorttoggleComponent = () => (
    <svg width={50} height={175.5} viewBox="0 0 50 175.5">
      <defs>
        <style>
          {
            ".a{fill:#4ece3d;}.b{fill:#ed4a51;opacity:0.247;}.c{fill:#fff;font-size:15px;font-family:Heebo-Medium, Heebo;font-weight:500;letter-spacing:0.155em;}"
          }
        </style>
      </defs>
      <g transform="translate(0 175.5) rotate(-90)">
        <rect className="a" width={87.5} height={50} />
        <rect
          className="b"
          width={87.5}
          height={50}
          transform="translate(87.5)"
        />
        <text className="c" transform="translate(0 14.378)">
          <tspan x={20.845} y={16}>
            {"LONG"}
          </tspan>
        </text>
        <text className="c" transform="translate(87.5 14.378)">
          <tspan x={14.947} y={16}>
            {"SHORT"}
          </tspan>
        </text>
      </g>
    </svg>
  );

  return (
    <Layout>

      <div className="bg-white flex flex-col">
        <div className="container mx-auto px-8">

          <div className="bg-cover lg:bg-contain bg-top bg-no-repeat -mx-16" style={{backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,.5) 60%, rgba(255,255,255,1) 95%),url("/img/Mask Group 4@2x.png")`}}>
            <div className="mx-16">

              {/* hero */}
              <main className="flex flex-wrap items-center text-center md:text-left my-12 py-48 lg:px-24">
                <div className="w-full md:w-full whitespace-pre-line">
                  <h1 className="text-5xl text-black leading-tight mb-6">
                    Order Management{`\n`}
                    <span className="font-bold">for Humans</span>
                  </h1>
                  <SubscribeForm />
                </div>
              </main>


              {/* subhero section */}
              <div className="flex flex-wrap overflow-hidden text-center py-12 my-12 items-center">

                <div className="w-full overflow-hidden whitespace-pre-line">
                  <h2 className="text-3xl font-bold pb-2">
                    Become a trading{`\n`}
                    superhuman.
                  </h2>
                  <h3 className="text-lg">
                    Offload emotion and memory overhead to robots.{`\n`}
                    <span className="font-medium">
                      So you can focus.
                    </span>
                  </h3>
                </div>

              </div>
            </div>
          </div>

          {/* first section */}
          <div className="flex flex-wrap overflow-hidden text-left py-12 my-12 items-center">
            {/* left */}
            <div className="w-full md:w-1/2 overflow-hidden">
              {/* tabs */}
              <nav className="bg-white px-8 pt-2 shadow-md mb-6">
                 <div className="-mb-px flex justify-center">
                     <a className="no-underline text-teal-dark border-b-2 border-teal-dark uppercase tracking-wide font-medium text-xs py-3 mr-8" href="#">
                         Overview
                     </a>
                     <a className="no-underline text-gray-dark border-b-2 border-transparent uppercase tracking-wide font-medium text-xs py-3 mr-8" href="#">
                         Entry
                     </a>
                     <a className="no-underline text-gray-dark border-b-2 border-transparent uppercase tracking-wide font-medium text-xs py-3 mr-8" href="#">
                        <span className="hidden sm:block">Take Profit</span>
                        <span className="sm:hidden">TP</span>
                     </a>
                     <a className="no-underline text-gray-dark border-b-2 border-transparent uppercase tracking-wide font-medium text-xs py-3" href="#">
                       <span className="hidden sm:block">Stop Loss</span>
                       <span className="sm:hidden">SL</span>
                     </a>
                 </div>
              </nav>
              {/* order controls */}
              <div className="flex">
                <div className="flex-initial mx-4">
                  <ButtonsLongShorttoggleComponent></ButtonsLongShorttoggleComponent>
                </div>
                <div className="flex-grow">
                  <table className="table-auto border-0 w-full text-sm">
                    <tbody>
                      <tr className="border-b">
                        <td className="px-2 py-1 uppercase font-medium text-gray-500 tracking-wider">Entry</td>
                        <td className="px-2 py-1 font-bold">
                          <input className="appearance-none w-full text-gray-700 leading-tight focus:outline-none font-bold" id="" type="text" defaultValue="123.4" />
                        </td>
                        <td className="px-2 py-1 text-gray-500 text-right"><FontAwesomeIcon icon={faCog} /></td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-2 py-1 uppercase font-medium text-gray-500 tracking-wider">Take Profit</td>
                        <td className="px-2 py-1 font-bold">133.45</td>
                        <td className="px-2 py-1 text-gray-500 text-right"><FontAwesomeIcon icon={faCog} /></td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-2 py-1 uppercase font-medium text-gray-500 tracking-wider">Stop Loss</td>
                        <td className="px-2 py-1 font-bold">{"function"}</td>
                        <td className="px-2 py-1 text-gray-500 text-right"><FontAwesomeIcon icon={faCog} /></td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-2 py-1 uppercase font-medium text-gray-500 tracking-wider">% Risk / Qty</td>
                        <td className="px-2 py-1 font-bold">2.5%&nbsp;<span className="text-gray-500">now</span></td>
                        <td className="px-2 py-1 text-gray-500 text-right">4640</td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-2 py-1 uppercase font-medium text-gray-500 tracking-wider">Expiry</td>
                        <td className="px-2 py-1 font-bold">20/05/15 00:00 UTC</td>
                        <td className="px-2 py-1 text-gray-500 text-right">in 24 days</td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-2 py-1 uppercase font-medium text-gray-500 tracking-wider">Invalidation</td>
                        <td className="px-2 py-1 font-bold">{"6h_TK < 6h_KJ"}</td>
                        <td className="px-2 py-1 text-gray-500 text-right"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            {/* right */}
            <div className="w-full md:w-1/2 overflow-hidden p-12 lg:px-24">
              <h2 className="text-2xl font-bold pb-6">
                Make fewer mistakes.
              </h2>
              <p className="text-base pb-6">
                Determine entries, take profits, and stop losses. Then set it and forget it. We’ll take care of the rest - and keep you updated.
              </p>
              {/* mini section */}
              <div className="flex flex-wrap overflow-hidden -mx-6 whitespace-pre-line">
                <div className="px-6 w-1/2 overflow-hidden">
                  <h3 className="text-sm pb-2 font-medium">
                    Risk management
                  </h3>
                  <p className="text-xs">
                    Position sizes can be automatically calculated based on risk and trade parameters.
                  </p>
                </div>

                <div className="px-6 w-1/2 overflow-hidden">
                  <h3 className="text-sm pb-2 font-medium">
                    Trade rules
                  </h3>
                  <p className="text-xs">
                    Protect yourself, from yourself.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* second section */}
          <div className="flex flex-wrap overflow-hidden text-left py-12 my-12 items-center">
            {/* right */}
            <div className="md:order-2 w-full md:w-1/2 overflow-hidden">
              {/* tabs */}
              <nav className="bg-white px-8 pt-2 shadow-md mb-6">
                 <div className="-mb-px flex justify-center">
                     <a className="no-underline text-teal-dark border-b-2 border-teal-dark uppercase tracking-wide font-medium text-xs py-3 mr-8" href="#">
                         Overview
                     </a>
                     <a className="no-underline text-gray-dark border-b-2 border-transparent uppercase tracking-wide font-medium text-xs py-3 mr-8" href="#">
                         Entry
                     </a>
                     <a className="no-underline text-gray-dark border-b-2 border-transparent uppercase tracking-wide font-medium text-xs py-3 mr-8" href="#">
                        <span className="hidden sm:block">Take Profit</span>
                        <span className="sm:hidden">TP</span>
                     </a>
                     <a className="no-underline text-gray-dark border-b-2 border-transparent uppercase tracking-wide font-medium text-xs py-3" href="#">
                       <span className="hidden sm:block">Stop Loss</span>
                       <span className="sm:hidden">SL</span>
                     </a>
                 </div>
              </nav>
              {/* advanced order controls */}
              <div className="flex items-center">
                <div className="flex-initial mx-4 uppercase text-sm">
                  <ul className="font-medium text-gray-500 tracking-wider">
                    <li className="px-2 py-2">Single</li>
                    <li className="px-2 py-2">Multiple</li>
                    <li className="px-2 py-2 underline text-gray-800">Scaled</li>
                    <li className="px-2 py-2">{"Function"}</li>
                  </ul>
                </div>
                <div className="flex-grow">
                  <OrderScaleShape className="fill-current text-teal-500 mx-5 md:mx-10" data={[[1,10], [2, 15], [3, 20], [4, 30], [5, 40], [6, 60], [7, 80]]} width={150} height={100} resolution={[8, 100]}></OrderScaleShape>
                </div>
              </div>
            </div>

            {/* left */}
            <div className="md:order-1 w-full md:w-1/2 overflow-hidden p-12 lg:px-24">
              <h2 className="text-2xl font-bold pb-6">
                Advanced order types.
              </h2>
              <p className="text-base pb-6">
                Dynamic orders based on technical indicators. Nonlinear scaling orders. OCO orders before your entry is executed.
                Do what you need, without relying on exchange features.
              </p>
            </div>
          </div>

          {/* third section */}
          <div className="flex flex-wrap overflow-hidden text-left py-12 my-12 items-center">
            {/* left */}
            <div className="md:w-1/2 overflow-hidden">
              <img src="/img/increase_productivity@2x.png"></img>
            </div>

            {/* right */}
            <div className="md:w-1/2 overflow-hidden p-12 lg:px-24">
              <h2 className="text-2xl font-bold pb-6">
                Increase productivity
              </h2>
              <p className="text-base pb-6">
                Manage 10x the trade ideas across 10x the brokers.{`\n`}
                Develop strategies for multiple timeframes and asset classes without having to remember every detail.
              </p>
              {/* mini section */}
              <div className="flex flex-wrap overflow-hidden -mx-6 whitespace-pre-line">
                <div className="px-6 w-1/2 overflow-hidden">
                  <h3 className="text-sm pb-2 font-medium">
                    Trade Journal
                  </h3>
                  <p className="text-xs">
                    Keep track of all your trade ideas, past and present.
                  </p>
                </div>

                <div className="px-6 w-1/2 overflow-hidden">
                  <h3 className="text-sm pb-2 font-medium">
                    Charting
                  </h3>
                  <p className="text-xs">
                    View multiple active trades on a single chart.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* fourth section */}
          <div className="flex flex-wrap overflow-hidden text-left py-12 my-12 items-center">
            {/* right */}
            <div className="md:order-2 w-full md:w-1/2 overflow-hidden">
              <img src="/img/Line Chart1.svg"></img>
            </div>

            {/* left */}
            <div className="md:order-1 w-full md:w-1/2 overflow-hidden p-12 lg:px-24">
              <h2 className="text-2xl font-bold pb-6">
                Trade Analytics
              </h2>
              <p className="text-base pb-6">
                See which of your strategies work, and eliminate those that don’t. Develop trade discipline that makes trading fun, rewarding, and most importantly, sustainable.
              </p>
              {/* mini section */}
              <ul className="list-disc list-inside">
                <li>Real-time overview</li>
                <li>Performance reports</li>
                <li>Trade review</li>
              </ul>
            </div>
          </div>

          {/* pre footer image */}
          {/*<img className="w-full px-" src="/img/whiteboarding.svg"></img>*/}
          {/*<img className="w-full h-64"></img>*/}

          {/* cta */}
          <div className="bg-gray-50">
            <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Ready to dive in?
                <br />
                <span className="text-indigo-600">Start trading today.</span>
              </h2>
              <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
                <div className="inline-flex">
                  <a href="#" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Get started
                  </a>
                </div>
                <div className="ml-3 inline-flex ">
                  <a href="#" className="inline-flex items-center justify-center px-5 py-3 border border-purple-500 text-base leading-6 font-medium text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Live demo
                  </a>
                </div>
              </div>
            </div>
          </div>


          {/* prefooter */}
          <div className="flex flex-wrap -mx-6 overflow-hidden mb-12">

            <div className="my-2 px-8 md:w-1/3 w-full md:order-2 overflow-hidden">
              <div className="flex flex-wrap -mx-2 overflow-hidden">

                <div className="px-2 w-1/2 overflow-hidden">
                  {/*<h5 className="text-sm mb-4 font-medium">
                    First col
                  </h5>
                  <p className="text-xs text-gray-500 mb-2">
                    First page
                  </p>
                  <p className="text-xs text-gray-500 mb-2">
                    Second page
                  </p>
                  <p className="text-xs text-gray-500 mb-2">
                    Third page
                  </p>*/}
                </div>

                <div className="px-8 md:px-2 w-1/2 overflow-hidden">
                  {/*<h5 className="text-sm mb-4 font-medium">
                    Second col
                  </h5>
                  <p className="text-xs text-gray-500 mb-2">
                    First page
                  </p>
                  <p className="text-xs text-gray-500 mb-2">
                    Second page
                  </p>
                  <p className="text-xs text-gray-500 mb-2">
                    Third page
                  </p>*/}
                </div>

              </div>
            </div>

            <div className="my-2 px-8 md:w-1/3 w-1/2 md:order-1 overflow-hidden">
              <div className="mb-6">
                <Logo />
              </div>

              <p className="text-xs text-gray-500 mb-4">
                Join our newsletter to stay up to date on features and releases
              </p>

              <SubscribeForm
                className="w-full max-w-sm mb-4"
                inputPlaceholder1="Enter your email address"
                inputClass2="hidden"
                inputClass1="bg-gray-200 appearance-none border-2 border-gray-200 w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 text-xs"
                buttonClass="hidden" />

              {/*
                <a href="https://twitter.com/meowware"><FontAwesomeIcon icon={faTwitter} className="mx-2 text-gray-500" /></a>
              */}
              <a href="https://t.me/meowware"><FontAwesomeIcon icon={faTelegram} className="mx-2 text-gray-500" /></a>
            </div>

            <div className="my-2 px-8 md:w-1/3 w-1/2 md:order-3 overflow-hidden">
              <h5 className="text-sm mb-4 medium">
                Disclaimer
              </h5>
              <p className="text-xs text-gray-500">
                There are risks associated with investing. Investing in stocks, bonds, exchange traded funds, mutual funds, money market funds, and cryptocurrency involve risk of loss. The content on this website is for entertainment purposes only and do not constitute investment advice.
              </p>
            </div>

          </div>


        </div>
      </div>

    </Layout>
  )
}

export default LandingPage
