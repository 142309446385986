import React from 'react';
import Axis from './axis';
import GraphBody from './graph_body';

export default class OrderScaleShape extends React.Component {
  static defaultProps = { pb: 10, pl: 10, pt: 10, pr: 10};

  render() {
    return (
      <svg className={this.props.className} viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
        {/*<Axis
          x={this.props.pl}
          y={this.props.height - this.props.pb}
          length={this.props.width - this.props.pl - this.props.pr}
          horizontal={true}
        />
        <Axis
          x={this.props.pl}
          y={this.props.pt}
          length={this.props.height - this.props.pb - this.props.pt}
          horizontal={false}
        />*/}
        <GraphBody
          x={this.props.pl}
          y={this.props.height - this.props.pb}
          width={this.props.width - this.props.pl - this.props.pr}
          height={this.props.height - this.props.pb - this.props.pt}
          data={this.props.data}
          resolution={this.props.resolution}
        />
        {"<!-- End Points -->"}
        <circle cx={this.props.pl} cy={this.props.height - this.props.pb} r="2.5" fill="white" className="stroke-current text-blue-500"/>
        <circle cx={this.props.width-this.props.pr} cy={this.props.pt} r="2.5" fill="white" className="stroke-current text-blue-500"/>
      </svg>
    )
  }
}
