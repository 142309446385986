import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
const url = "//meowware.us19.list-manage.com/subscribe/post?u=93c68bc3169d90850d9cf570e&amp;id=11e35c42eb";

class CustomForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      EMAIL: "",
      b_93c68bc3169d90850d9cf570e_11e35c42eb: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault()
    let email = this.state.EMAIL

    email &&
    email.indexOf("@") > -1 &&
    this.props.onValidated(this.state)
  }

  handleChange(event) {
    const {name, value} = event.target
    this.setState({[name]: value})
  }

  render() {
    const { status, message, onValidated, className, inputClass1, inputPlaceholder1, inputClass2, inputPlaceholder2, buttonClass, errorClass } = this.props

    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={className}
        >
          <input
            className={inputClass1}
            placeholder={inputPlaceholder1}
            type="email"
            name="EMAIL"
            value={this.state.EMAIL}
            onChange={this.handleChange}
          />
          <input
            className={inputClass2}
            placeholder={inputPlaceholder2}
            type="email"
            name="EMAIL"
            value={this.state.EMAIL}
            onChange={this.handleChange}
          />

          {/*Anti bot*/}
          <div style={{position: "absolute", left: "-5000px", ariaHidden:"true"}}>
            <input type="text" name="b_93c68bc3169d90850d9cf570e_11e35c42eb" tabIndex="-1" value={this.state.b_93c68bc3169d90850d9cf570e_11e35c42eb} onChange={this.handleChange} />
          </div>

          <button className={buttonClass}>
            {status === "sending" ? <span className="text-blue-200">Submit</span> : `Submit`}
          </button>
        </form>

        <div className={errorClass}>
          {status === "error" && (
            <div
              className="text-red-600"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="text-green-600"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      </div>
    )
  }
}

CustomForm.defaultProps = {
  inputPlaceholder1: "Sign up for the upcoming beta!",
  inputPlaceholder2: "Sign up for the beta!",
  className: "w-full sm:max-w-sm flex items-center pb-2",
  inputClass1: "hidden sm:block rounded-none w-full px-4 py-2 mr-0 appearance-none bg-indigo-100 focus:outline-none focus:bg-white border-indigo-500 text-gray-900 border-2 box-border border-indigo-100 placeholder-gray-600 text-sm",
  inputClass2: "sm:hidden rounded-none w-full px-4 py-2 mr-0 appearance-none bg-indigo-100 focus:outline-none focus:bg-white border-indigo-500 text-gray-900 border-2 box-border border-indigo-100 placeholder-gray-600 text-sm",
  buttonClass: "transition duration-150 ease-in-out rounded-none p-2 px-8 appearance-none bg-indigo-500 text-white border-2 box-border border-indigo-500 hover:bg-indigo-400 text-sm font-bold focus:outline-none focus:bg-indigo-700",
  errorClass: "w-full sm:max-w-sm flex items-center text-xs whitespace-pre-line"
}

export default function (props) {
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={subscribe}
          {...props}
        />
      )}
    />
  )
}
